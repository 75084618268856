<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as SUPPLIER_ORG_TYPES } from './store'
import { ROUTES as SUPPLIER_ORG_ROUTES } from './router'

import SupplierOrganizationTable from './SupplierOrganizationTable'
import SupplierOrganizationCommonFilters from './SupplierOrganizationCommonFilters'
import OrganizationListMixin from '../../../components/organization/mixins/OrganizationListMixin'

export default {
  name: 'SupplierOrganizationList',
  mixins: [OrganizationListMixin],
  components: {
    'component-table': SupplierOrganizationTable,
    'common-filters': SupplierOrganizationCommonFilters
  },
  data () {
    return {
      SUPPLIER_ORG_ROUTES,
      title: this.$t('Supplier organizations'),
      rolePerm: ['organization_supplierorganization_list'],
      actionEnablePermission: ['organization_supplierorganization_enable'],
      actionDisablePermission: ['organization_supplierorganization_disable'],
      orgType: this.$route.params.orgType
    }
  },
  computed: {
    ...mapGetters({
      ...SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_LIST
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS
    }),
    openEditForm (id) {
      this.$router.push({ name: this.SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_CREATE })
    },
    openDetailForm (id) {
      this.$router.push({ name: this.SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DETAIL, params: { id: id } })
    },
    getComponentList () {
      if (this.orgType) {
        return this.setFilters({ filter__organization_types__code: this.orgType })
      } else {
        return this.setFilters({})
      }
    }
  }
}
</script>
