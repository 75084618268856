<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as SUPPLIER_ORG_TYPES } from './store'
import { ROUTES as SUPPLIER_ORG_ROUTES } from './router'

import OrganizationTableMixin from '../../../components/organization/mixins/OrganizationTableMixin'
import { ORGANIZATION_SUPPLIER_CODE } from '../../../../../const'

export default {
  name: 'SupplierOrganizationTable',
  mixins: [OrganizationTableMixin],
  data () {
    return {
      showDocumentsButton: true,
      showPrivacyButton: false,
      showDownloadDocumentsButton: true,
      showCompanyRegistryButton: true
    }
  },
  computed: {
    ...mapGetters({
      selectAll: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.selectAll,
      selectedList: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.GETTERS.selectedList
    })
  },
  methods: {
    ...mapMutations({
      setList: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.MUTATIONS.setList,
      setSelectedList:
        SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...SUPPLIER_ORG_TYPES.GENERIC.organization.supplierorganization.LIST.ACTIONS
    }),
    getDocumentsRouter (id, name) {
      return {
        name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_DOCUMENTS,
        params: { orgId: id, orgName: name, orgType: ORGANIZATION_SUPPLIER_CODE }
      }
    },
    getInspectionRouter (id, name) {
      return {
        name: SUPPLIER_ORG_ROUTES.SUPPLIER_ORGANIZATION_INSPECTIONS_LIST,
        params: { id: id, orgName: name, orgType: ORGANIZATION_SUPPLIER_CODE }
      }
    }
  }
}
</script>
